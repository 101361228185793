// src/pages/LandingPage/LandingPage.jsx
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import HowItWorks from './components/HowItWorks';
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
const LandingPage = () => {
  const navigate = useNavigate();

  // State for pricing counters
  const [counters, setCounters] = useState({
    free: 0,
    premium: 1
  });

  // State for pricing calculations
  const [pricing, setPricing] = useState({
    freePrice: 0,
    premiumPrice: 4780,
    total: 4780
  });

  // State for dropdowns
  const [activeDropdowns, setActiveDropdowns] = useState({
    features: new Set(),
    faq: new Set()
  });

  // State for navbar scroll effect
  const [isScrolled, setIsScrolled] = useState(false);

  // Handle navigation
  const handleNavigate = (path) => {
    navigate(path);
  };

  // Handle dropdown toggles
  const toggleDropdown = (section, id) => {
    setActiveDropdowns(prev => {
      const newSet = new Set(prev[section]);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return {
        ...prev,
        [section]: newSet
      };
    });
  };

  // Handle counter updates
  const updateCounter = (type, increment) => {
    setCounters(prev => {
      let newCount;
      if (type === 'free') {
        newCount = increment ?
          Math.min(prev.free + 1, 1) :
          Math.max(prev.free - 1, 0);
      } else {
        newCount = increment ?
          prev.premium + 1 :
          Math.max(prev.premium - 1, 0);
      }

      return {
        ...prev,
        [type]: newCount
      };
    });
  };

  // Calculate total price whenever counters change
  useEffect(() => {
    setPricing(prev => {
      const freeCost = counters.free * prev.freePrice;
      const premiumCost = counters.premium * prev.premiumPrice;
      return {
        ...prev,
        total: freeCost + premiumCost
      };
    });
  }, [counters]);

  // Handle scroll effects
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Handle smooth scrolling for anchor links
  useEffect(() => {
    const handleAnchorClick = (e) => {
      const href = e.target.getAttribute('href');
      if (href?.startsWith('#')) {
        e.preventDefault();
        const element = document.querySelector(href);
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth'
          });
        }
      }
    };

    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach(link => {
      link.addEventListener('click', handleAnchorClick);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleAnchorClick);
      });
    };
  }, []);

  // Animation observer setup
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });

    const elements = document.querySelectorAll('.animate-on-scroll');
    elements.forEach(el => observer.observe(el));

    return () => elements.forEach(el => observer.unobserve(el));
  }, []);

  // Shared props for child components
  const sharedProps = {
    isScrolled,
    handleNavigate,
    toggleDropdown,
    activeDropdowns,
    counters,
    pricing,
    updateCounter
  };

  return (
    <div className="home">
      <Header
        isScrolled={isScrolled}
        handleNavigate={handleNavigate}
      />

      <Hero
        handleNavigate={handleNavigate}
      />

      <Features
        activeDropdowns={activeDropdowns.features}
        toggleDropdown={(id) => toggleDropdown('features', id)}
      />

      <HowItWorks />

      <Pricing
        counters={counters}
        pricing={pricing}
        updateCounter={updateCounter}
      />

      <FAQ
        activeDropdowns={activeDropdowns.faq}
        toggleDropdown={(id) => toggleDropdown('faq', id)}
      />

      <Footer />
    </div>
  );
};

export default LandingPage;
