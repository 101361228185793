// src/pages/LandingPage/components/Footer.jsx
import React from 'react';

const footerLinks = [
  { href: '#home', text: 'Home' },
  { href: '#about', text: 'About Us' },
  { href: '#services', text: 'Services' },
  { href: '#contact', text: 'Contact' }
];

const socialLinks = [
  {
    platform: 'Instagram',
    url: 'https://instagram.com',
    icon: '/assets/img/instagram-logo.png'
  },
  {
    platform: 'Facebook',
    url: 'https://facebook.com',
    icon: '/assets/img/facebook-logo.png'
  },
  {
    platform: 'Twitter',
    url: 'https://twitter.com',
    icon: '/assets/img/x-logo.png'
  }
];

const Footer = () => {
  return (
    <footer className="footerSection">
      <div className="footer-separator"></div>
      <div className="footer-container">
        <div className="footer-contact">
          <h3>Contact Us</h3>
          <p>Email: info@profitx.hu</p>
          <p>Phone: +36 70 675 4008</p>
        </div>

        <div className="footer-links">
          <h3>Quick Links</h3>
          <ul>
            {footerLinks.map((link, index) => (
              <li key={index}>
                <a href={link.href}>{link.text}</a>
              </li>
            ))}
          </ul>
        </div>

        <div className="footer-social">
          <h3>Follow Us</h3>
          <ul className="social-icons">
            {socialLinks.map((social, index) => (
              <li key={index}>
                <a
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={social.icon}
                    alt={social.platform}
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
