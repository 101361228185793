import { API_URL } from '../config';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import imgGroup1 from '../assetes/img/Group 466.png';
import imgGroup2 from '../assetes/img/Group 470.png';
// import imgGroup3 from '../assetes/img/Group 469.png';
// import imgGroup4 from '../assetes/img/Group 472.png';
import tharhIcon from '../assetes/img/trash.png'; // Import the new image
import './models.css';
import useActiveSheet from '../hooks/useActiveSheet'; // Import useActiveSheet hook
import { loadStripe } from '@stripe/stripe-js';

const platformImages = {
  Hardverapró: imgGroup2,
  Jófogas: imgGroup1,
//  Facebook: imgGroup3,
//  Ebay: imgGroup4,
};

const Libraries = ["places"];

const ModelsMiddle = ({ sheets, activeSheetId, setActiveSheetId, market }) => {
  const navigate = useNavigate();
  const [scraperStatus, setScraperStatus] = useState(null); // To hold the current scraper status
  const [loading, setLoading] = useState(false);

  const handleSelectChange = (event) => {
    const selectedSheetId = event.target.value;
    setActiveSheetId(selectedSheetId);
    localStorage.setItem('activeSheetId', selectedSheetId);
  };

  const handleViewMarket = () => {
    navigate(`/models/market-edit/${market._id}`);
  };

  const getScraperStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/api/scraper/status/${market._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setScraperStatus(response.data.status);
    } catch (error) {
      console.error('Error fetching scraper status:', error);
    }
  };

  const startScraper = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      console.log(`${API_URL}/api/scraper/start/${market._id}`);
      console.log("token", token);
      await axios.post(`${API_URL}/api/scraper/start/${market._id}`,{}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      getScraperStatus(); // Update status after starting
    } catch (error) {
      console.error('Error starting scraper:', error);
    } finally {
      setLoading(false);
    }
  };

  const stopScraper = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/api/scraper/stop/${market._id}`,{}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      getScraperStatus(); // Update status after stopping
    } catch (error) {
      console.error('Error stopping scraper:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('activeSheetId in models middle', activeSheetId);
    getScraperStatus(); // Fetch the scraper status when the component loads
  }, []);

  return (
    <div className="models-middle">
      <div className="market-info">
        <div className='market-title'>
          <h3>{market.keyword}</h3>
          <button className="add-market-button" onClick={handleViewMarket}>Megtekintés</button>
        </div>
        <div className="info-row">
          <div className="info-box">
            <p>Talált Profit</p>
            <p className="info-value">1,247,030 Ft</p>
          </div>
          <div className="info-box">
            <p>Potenciális Termékek</p>
            <p className="info-value">97</p>
          </div>
        </div>
        <div className='model-bottom'>
          <div className="markets">
            <p>Platformok</p>
            <div className="market-logos">
              {market.markets.map((platform, index) => (
                <img key={index} src={platformImages[platform]} alt={platform} />
              ))}
            </div>
          </div>
          <div className="price-sheet">
            <label htmlFor="priceSheet">Min/Max Árlista</label>
            <div className='price-sheet-select'>
              <p>Min: {market.minPrice || 'N/A'}</p>
              <p>Max: {market.maxPrice || 'N/A'}</p>
            </div>
            {/* <select id="priceSheet" value={activeSheetId ? activeSheetId._id : ''} onChange={handleSelectChange}>
              {sheets.map(sheet => (
                <option key={sheet._id} value={sheet._id}>{sheet.name}</option>
              ))}
            </select> */}
          </div>
        </div>

        {/* Scraper Controls */}
        <div className="scraper-controls">
          <p>Scraper Status: {scraperStatus || 'Unknown'}</p>
          <button onClick={startScraper} disabled={loading || scraperStatus === 'running'}>
            Start Scraper
          </button>
          <button onClick={stopScraper} disabled={loading || scraperStatus !== 'running'}>
            Stop Scraper
          </button>
        </div>
      </div>
    </div>
  );
};

const ModelsRight = ({ sheets, onAddNewSheet, newSheetName, setNewSheetName, errorMessage, onEditSheet, onDeleteSheet }) => {
  return (
    <div className="models-right">
      <h2>Árlisták</h2>
      {sheets.map(sheet => (
        <div className="price-sheet-right-container" key={sheet._id}>
          <div className="price-sheet-right">
            <p>{sheet.name}</p>
            <span className="material-symbols-outlined" onClick={() => onEditSheet(sheet._id)}>edit_note</span>
          </div>
          <img src={tharhIcon} alt="Trash Icon" onClick={() => onDeleteSheet(sheet)} />
        </div>
      ))}
      {sheets.length < 3 && (
        <div>
          <input
            type="text"
            value={newSheetName}
            onChange={(e) => setNewSheetName(e.target.value)}
            placeholder="Új lista neve"
          />
          <button className="add-new" onClick={onAddNewSheet}>Create Sheet</button>
        </div>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

const Models = () => {
  const [sheets, setSheets] = useState([]);
  const [newSheetName, setNewSheetName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // const [activeSheetId, setActiveSheetId] = useState(localStorage.getItem('activeSheetId') || '');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [sheetToDelete, setSheetToDelete] = useState(null);
  const [markets, setMarkets] = useState([]); // State to store the list of markets
  const [user, setUser] = useState({});
  const [newMarket, setNewMarket] = useState({ keyword: '', markets: [], location: '',  plan: 'Alap', priceRange: { minPrice: '', maxPrice: '' },  }); // State for new market form
  const [isMarketPopupVisible, setIsMarketPopupVisible] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null); // State to store Autocomplete instance
  const navigate = useNavigate();
  const { activeSheet, error, activateSheet } = useActiveSheet();
  const [selectedPlan, setSelectedPlan] = useState('free');
  const [quantity, setQuantity] = useState(1);
  const [subscriptionSummary, setSubscriptionSummary] = useState({
    free: { max: 1, current: 0, active: true, subscriptions: [] },
    basic: { max: 0, current: 0, active: false, subscriptions: [] },
    premium: { max: 0, current: 0, active: false, subscriptions: [] }
  });
  const location = useLocation(); // Add this at the top with other hooks

  useEffect(() => {
    // Handle market deletion feedback
    if (location.state?.marketDeleted) {
      // Remove the deleted market from local state
      setMarkets(prevMarkets =>
        prevMarkets.filter(market => market._id !== location.state.marketId)
      );

      // Update subscription summary with the new data, ensuring free plan is properly set
      if (location.state.updatedSubscriptionSummary) {
        const updatedSummary = {
          ...location.state.updatedSubscriptionSummary,
          free: {
            ...location.state.updatedSubscriptionSummary.free,
            max: 1,
            active: true
          }
        };
        setSubscriptionSummary(updatedSummary);
      }

      // Clear the location state
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  useEffect(() => {

    const fetchSheets = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/sheets`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSheets(response.data);
      } catch (error) {
        console.error('Error fetching sheets:', error);
      }
    };

    const fetchMarkets = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/markets`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setMarkets(response.data);
      } catch (error) {
        console.error('Error fetching markets:', error);
      }
    };

    const fetchUserMe = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/users/me`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    const fetchSubscriptionSummary = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/subscriptions/summary`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        // Ensure free plan is always available with max 1
        const updatedSummary = {
          ...response.data,
          free: {
            ...response.data.free,
            max: 1, // Always ensure max is 1 for free plan
            active: true // Always active
          }
        };

        setSubscriptionSummary(updatedSummary);
      } catch (error) {
        console.error('Error fetching subscription summary:', error);
        // If there's an error, at least ensure the free plan is shown correctly
        setSubscriptionSummary(prev => ({
          ...prev,
          free: { ...prev.free, max: 1, active: true }
        }));
      }
    };

    fetchSheets();
    fetchMarkets();
    fetchUserMe();
    fetchSubscriptionSummary();
  }, []);

  const handleCreateSheet = async () => {
    try {
      const token = localStorage.getItem('token');
      // Ensure the new sheet is created with 'active: true'
      const response = await axios.post(`${API_URL}/api/sheets`, {
        name: newSheetName,
        products: [],
        active: true // Set the sheet as active
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Update sheets state
      setSheets([...sheets, response.data]);

      // Set the new sheet as the active one
      activateSheet(response.data._id);
      localStorage.setItem('activeSheetId', response.data._id);

      setNewSheetName('');
      setErrorMessage('');
    } catch (error) {
      console.error('Error creating sheet:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('An error occurred while creating the sheet.');
      }
    }
  };

  const handleEditSheet = (sheetId) => {
    navigate(`/models/sheet-edit/${sheetId}`);
  };

  const handleDeleteSheet = (sheet) => {
    setSheetToDelete(sheet);
    setIsPopupVisible(true);
  };

  const confirmDeleteSheet = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/api/sheets/${sheetToDelete._id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSheets(sheets.filter(sheet => sheet._id !== sheetToDelete._id));
      setIsPopupVisible(false);
      setSheetToDelete(null);
    } catch (error) {
      console.error('Error deleting sheet:', error);
      setErrorMessage('An error occurred while deleting the sheet.');
      setIsPopupVisible(false);
      setSheetToDelete(null);
    }
  };

    // Handle when a location is selected
    const handlePlaceSelect = () => {
      if (autocomplete !== null) {
        const place = autocomplete.getPlace();
        if (place.formatted_address) {
          setNewMarket({ ...newMarket, location: place.formatted_address });
        }
      }
    };
  const updateSubscriptionSummary = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/api/subscriptions/summary`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSubscriptionSummary(response.data);
    } catch (error) {
      console.error('Error fetching subscription summary:', error);
    }
  };
  const handleCreateMarket = async () => {
    try {
      const token = localStorage.getItem('token');

      // Check for available subscription if plan is not free
      if (selectedPlan !== 'free') {
        const subscriptionResponse = await axios.get(
          `${API_URL}/api/subscriptions/available/${selectedPlan}`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        if (!subscriptionResponse.data.subscription) {
          // No available subscription, redirect to pricing page
          navigate('/pricing');
          return;
        }

        // Create market with existing subscription
        const marketResponse = await axios.post(
          `${API_URL}/api/markets`,
          {
            ...newMarket,
            subscriptionId: subscriptionResponse.data.subscription._id
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        setMarkets([...markets, marketResponse.data]);
        setIsMarketPopupVisible(false);
        setNewMarket({ keyword: '', markets: [], location: '' });
        await updateSubscriptionSummary(); // U
      } else {
        // Handle free plan as before
        const marketResponse = await axios.post(
          `${API_URL}/api/markets`,
          {
            ...newMarket,
            plan: selectedPlan
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        setMarkets([...markets, marketResponse.data]);
        setIsMarketPopupVisible(false);
        setNewMarket({ keyword: '', markets: [], location: '' });
        await updateSubscriptionSummary(); // Update subscription summary
      }
    } catch (error) {
      console.error('Error creating market:', error);
      if (error.response?.data?.error === 'Subscription quantity limit reached') {
        // Handle subscription limit reached - maybe show upgrade prompt
        alert('You have reached your subscription limit. Please upgrade your plan.');
      } else {
        setErrorMessage('An error occurred while creating the market.');
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMarket({ ...newMarket, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const updatedMarkets = checked
      ? [...newMarket.markets, value]
      : newMarket.markets.filter((market) => market !== value);

    setNewMarket({ ...newMarket, markets: updatedMarkets });
  };

  const SubscriptionStatus = () => (
    <div className="subscription-status">
      <h3>Your Subscriptions</h3>
      <div className="subscription-grid">
        {Object.entries(subscriptionSummary).map(([plan, details]) => (
          <div key={plan} className={`subscription-card ${details.active ? 'active' : ''}`}>
            <h4>{plan.charAt(0).toUpperCase() + plan.slice(1)} Plan</h4>
            <div className="subscription-details">
              <p>Total Markets: {details.current} / {plan === 'free' ? 1 : details.max}</p>
              <div className="usage-bar">
                <div
                  className="usage-fill"
                  style={{
                    width: `${plan === 'free' ? (details.current / 1) * 100 : details.max ? (details.current / details.max) * 100 : 0}%`,
                    backgroundColor: details.current >= (plan === 'free' ? 1 : details.max) ? '#ff4444' : '#44aa44'
                  }}
                />
              </div>
              {details.subscriptions?.map((sub, index) => (
                <div key={index} className="subscription-breakdown">
                  <p>Subscription {index + 1}: {sub.currentQuantity}/{plan === 'free' ? 1 : sub.maxQuantity}</p>
                  {sub.expiryDate && (
                    <p className="expiry-date">
                      Expires: {new Date(sub.expiryDate).toLocaleDateString()}
                    </p>
                  )}
                </div>
              ))}
              {!details.active && details.max === 0 && plan !== 'free' && (
                <button onClick={() => navigate('/pricing')}>
                  Get {plan} Plan
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const PlanSelection = () => (
    <div className="plan-selection">
      <h3 className='popup-title1'>Select Plan</h3>
      <select
        value={selectedPlan}
        onChange={(e) => setSelectedPlan(e.target.value)}
      >
        <option value="free" disabled={subscriptionSummary.free.current >= subscriptionSummary.free.max}>
          Free ({subscriptionSummary.free.current}/{subscriptionSummary.free.max} used) - 12h intervals
        </option>
        <option value="basic" disabled={subscriptionSummary.basic.current >= subscriptionSummary.basic.max}>
          Basic ({subscriptionSummary.basic.current}/{subscriptionSummary.basic.max} used) - 2h intervals
        </option>
        <option value="premium" disabled={subscriptionSummary.premium.current >= subscriptionSummary.premium.max}>
          Premium ({subscriptionSummary.premium.current}/{subscriptionSummary.premium.max} used) - 15min intervals
        </option>
      </select>

      {selectedPlan !== 'free' && !subscriptionSummary[selectedPlan]?.active && (
        <div className="subscription-warning">
          <p>You need an active {selectedPlan} subscription to create markets with this plan.</p>
          <button onClick={() => navigate('/pricing')}>Get Subscription</button>
        </div>
      )}
    </div>
  );

  return (
    <div className="models-container">
      <SubscriptionStatus />
      <div className="models">
        <div className="models-middle-container">
          {markets.map((market, index) => (
            <ModelsMiddle
              key={index}
              sheets={sheets}
              activeSheetId={activeSheet}
              setActiveSheetId={activateSheet}
              market={market}
            />
          ))}
        </div>
        <ModelsRight
          sheets={sheets}
          onAddNewSheet={handleCreateSheet}
          newSheetName={newSheetName}
          setNewSheetName={setNewSheetName}
          errorMessage={errorMessage}
          onEditSheet={handleEditSheet}
          onDeleteSheet={handleDeleteSheet}
        />
      </div>
      <button className="create-market-button" onClick={() => setIsMarketPopupVisible(true)}>új Piac Létrehozása</button>
      {isMarketPopupVisible && (
        <div className="popup">
          <div className="popup-content">
          <h3 className='pupup-title1'>Lokáció</h3>
          <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_PLACES_API} libraries={Libraries} language="hu">
            <Autocomplete
                      onLoad={setAutocomplete}
                      onPlaceChanged={handlePlaceSelect}
                      options={{
                        componentRestrictions: { country: 'hu' }, // Restrict to Hungary
                        types: ['(regions)'], // Allows city, district, county levels
                      }}
                    >
                <input

                  type="text"
                  name="location"
                  placeholder="Megye vagy város"
                  value={newMarket.location}
                  onChange={handleInputChange}
                />
              </Autocomplete>
            </LoadScript>
            <h3 className='pupup-title1'>Kereső szó</h3>
            <input
              type="text"
              name="keyword"
              placeholder="pl: iPhone, videókártya"
              value={newMarket.keyword}
              onChange={handleInputChange}
            />
            <p className='pupup-description1'>Ez az a kifejezés, amely szerint elindul a keresés.</p>
            <div className="checkbox-group">
              <label>
                <input
                  type="checkbox"
                  name="markets"
                  value="Jófogas"
                  checked={newMarket.markets.includes('Jófogas')}
                  onChange={handleCheckboxChange}
                />
                Jófogás
              </label>
              <label>
                <input
                  type="checkbox"
                  name="markets"
                  value="Hardverapró"
                  checked={newMarket.markets.includes('Hardverapró')}
                  onChange={handleCheckboxChange}
                />
                Hardverapró
              </label>
            </div>
            <div className='minmaxPrice-wrapper'>
            <h3 className='pupup-title1'>Min-Max Ár (opcionális)</h3>
            <div className='minmaxPrice'>
                <input
                  type="number"
                  name="minPrice"
                  value={newMarket.minPrice}
                  onChange={handleInputChange}
                  placeholder="Min Ár"
                />
                <input
                  type="number"
                  name="maxPrice"
                  value={newMarket.maxPrice}
                  onChange={handleInputChange}
                  placeholder="Max Ár"
                />
                </div>
                </div>
                <PlanSelection />

            <button onClick={handleCreateMarket}>Létrehozás</button>
            <button onClick={() => setIsMarketPopupVisible(false)}>Vissza</button>
          </div>
        </div>
      )}
      {isMarketPopupVisible && (
        <div className="popup-overlay" onClick={() => setIsMarketPopupVisible(false)} />
      )}
      {isPopupVisible && (
        <div className="popup">
          <div className="popup-content">
            <p>Kiválasztott lista: "{sheetToDelete?.name}"</p>
            <button onClick={confirmDeleteSheet}>Törlés</button>
            <button onClick={() => setIsPopupVisible(false)}>Vissza</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Models;
