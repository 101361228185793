// src/pages/LandingPage/components/HowItWorks.jsx
import React from 'react';

const ProductTable = () => (
  <table className="dummy-product-table-list">
    <thead>
      <tr>
        <th>Termék</th>
        <th>Memória</th>
        <th>Állapot</th>
        <th>Szolgáltató</th>
        <th>Eladási Ár</th>
      </tr>
    </thead>
    <tbody className='tr-list'>
      {[1, 2].map((_, index) => (
        <tr key={index}>
          <td>
            <div className="select-container">
              <select>
                <option>iPhone 11 Pro</option>
                <option>iPhone 14 Pro Max</option>
                <option>Samsung S23</option>
              </select>
            </div>
          </td>
          <td>
            <div className="select-container">
              <select>
                <option>64 GB</option>
                <option>128 GB</option>
                <option>512 GB</option>
              </select>
            </div>
          </td>
          <td>
            <div className="select-container">
              <select>
                <option>Újszerű</option>
                <option>Használt</option>
              </select>
            </div>
          </td>
          <td>
            <div className="select-container">
              <select>
                <option>Független</option>
                <option>Vodafone Függő</option>
              </select>
            </div>
          </td>
          <td>
            <input type="text" placeholder="Mennyiert tudsz eladni?" />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const ProductResults = () => (
  <table className="dummy-product-table">
    <thead>
      <tr>
        <th>Termék</th>
        <th>Memória</th>
        <th>Szolgáltató</th>
        <th>Állapot</th>
        <th>Ár</th>
        <th>Profit</th>
        <th>Platform</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>iPhone 11 Pro</td>
        <td>64 GB</td>
        <td>Független</td>
        <td>Újszerű</td>
        <td>46000 Ft</td>
        <td style={{ color: '#29b8d8' }}>24000 Ft</td>
        <td>Jófogás</td>
        <td><a href="#1">View</a></td>
      </tr>
      <tr>
        <td>iPhone 14 Pro Max</td>
        <td>512 GB</td>
        <td>Független</td>
        <td>Használt</td>
        <td>290000 Ft</td>
        <td style={{ color: 'white' }}>0 Ft</td>
        <td>Hardverapró</td>
        <td><a href="#1">View</a></td>
      </tr>
      <tr>
        <td>Samsung S23</td>
        <td>128 GB</td>
        <td>Vodafone Függő</td>
        <td>Használt</td>
        <td>240000 Ft</td>
        <td style={{ color: 'white' }}>0 Ft</td>
        <td>Facebook</td>
        <td><a href="#1">View</a></td>
      </tr>
    </tbody>
  </table>
);

const HowItWorks = () => {
  return (
    <section className="how-it-works-section">
      <div className="how-it-works-container">
        <div className="how-it-works-colLeft">
          <h4>Profit</h4>
          <h1>Saját Árlista</h1>
          <p>Adja meg az eladási árakat, és szűrje a termékeket pénzügyi szempontból. Értesítést küldünk minden olyan új termékről, amely nyereséget hozhat Önnek.</p>
        </div>
        <div className="how-it-works-colRight">
          <div className="dummy-sheet-edit">
            <ProductTable />
          </div>
        </div>
      </div>
      <div className="how-it-works-products">
        <ProductResults />
      </div>
    </section>
  );
};

export default HowItWorks;
