import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../config';
import './PaymentPages.css';

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading');
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        const token = localStorage.getItem('token');
        await axios.get(`${API_URL}/api/payment/verify/${sessionId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStatus('success');
      } catch (error) {
        console.error('Payment verification failed:', error);
        setStatus('error');
      }
    };

    if (sessionId) {
      verifyPayment();
    }
  }, [sessionId]);

  const handleContinue = () => {
    navigate('/models'); // Navigate back to the models page
  };

  return (
    <div className="payment-result-container">
      <div className="payment-result-card">
        {status === 'loading' && (
          <>
            <div className="loading-spinner"></div>
            <h2>Verifying your payment...</h2>
          </>
        )}

        {status === 'success' && (
          <>
            <div className="success-icon">✓</div>
            <h2>Payment Successful!</h2>
            <p>Your scraper has been activated and will start running according to your selected plan.</p>
            <button onClick={handleContinue} className="continue-button">
              Continue to Dashboard
            </button>
          </>
        )}

        {status === 'error' && (
          <>
            <div className="error-icon">⚠</div>
            <h2>Verification Failed</h2>
            <p>There was an issue verifying your payment. Please contact support.</p>
            <button onClick={handleContinue} className="continue-button">
              Return to Dashboard
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess;
