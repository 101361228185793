// src/pages/LandingPage/components/Pricing.jsx
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { API_URL } from '../../../config';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

const PricingPlan = ({
  type,
  title,
  price,
  features,
  counter,
  updateCounter,
  onPurchase
}) => {
  const isBusinessPlan = type === 'premium';
  const containerClass = isBusinessPlan ? 'home-pricing-table-business' : 'home-pricing-table-free';

  return (
    <div className={containerClass}>
      <div className={`table-${type}-heading`}>
        <div className={`${type}-heading-title`}>
          <h2>{title}</h2>
          <h3>{typeof price === 'number' ? `${price} Ft` : price}</h3>
        </div>
      </div>

      <p>It includes the following:</p>

      {features.map((feature, index) => (
        <div key={index} className="feature-item">
          <img src="/assets/img/check.png" alt="check" className="check-icon" />
          <span>{feature}</span>
        </div>
      ))}

      <div className="counter-section">
        <button
          className="counter-btn"
          onClick={() => updateCounter(type, false)}
        >
          -
        </button>
        <span>{counter}</span>
        <button
          className="counter-btn"
          onClick={() => updateCounter(type, true)}
        >
          +
        </button>
      </div>

      <button
        className="purchase-btn"
        onClick={onPurchase}
        disabled={type === 'premium' && counter === 0}
      >
        Purchase Now
      </button>
    </div>
  );
};

const PricingSummary = ({ counters, pricing }) => (
  <div className="home-pricing-summary">
    <h3>Summary</h3>
    <div className="summary-items">
      <p>{counters.free} × Basic Plan = {counters.free * pricing.freePrice} Ft</p>
      <p>{counters.premium} × Premium Plan = {counters.premium * pricing.premiumPrice} Ft</p>
    </div>
    <div className="summary-total">
      <p>
        <strong>
          Total: <span className="summary-total1">{pricing.total} Ft</span>
        </strong>
      </p>
    </div>
  </div>
);

const Pricing = ({ counters, pricing, updateCounter }) => {
  const [stripe, setStripe] = useState(null);
  const [checkout, setCheckout] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = localStorage.getItem('token');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [planToPurchase, setPlanToPurchase] = useState(null);
  const [quantityToPurchase, setQuantityToPurchase] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Initialize Stripe
    const initStripe = async () => {
      const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      setStripe(stripeInstance);
    };
    initStripe();
  }, []);

  useEffect(() => {
    // Check if there is a selected plan and quantity in the URL params
    const selectedPlan = searchParams.get('selectedPlan');
    const quantity = searchParams.get('quantity');

    if (selectedPlan && quantity && isAuthenticated) {
      setPlanToPurchase(selectedPlan);
      setQuantityToPurchase(parseInt(quantity, 10));
      setIsModalOpen(true);

      // Clear the URL parameters after setting the state
      navigate('/pricing', { replace: true });
    }
  }, [searchParams, isAuthenticated, navigate]);

  // Separate effect for checkout creation
  useEffect(() => {
    let isMounted = true;

    const initiateCheckout = async () => {
      if (!planToPurchase || !quantityToPurchase || !stripe || !isModalOpen) return;

      try {
        // Create checkout session
        const response = await fetch(`${API_URL}/api/payment/create-embedded-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            plan: planToPurchase,
            quantity: quantityToPurchase
          })
        });

        if (!isMounted) return;

        const { clientSecret } = await response.json();

        // Destroy existing checkout if it exists
        if (checkout) {
          checkout.destroy();
        }

        // Initialize embedded checkout
        const checkoutInstance = await stripe.initEmbeddedCheckout({
          clientSecret,
        });

        if (!isMounted) {
          checkoutInstance.destroy();
          return;
        }

        // Mount checkout
        checkoutInstance.mount('#checkout');
        setCheckout(checkoutInstance);
      } catch (error) {
        console.error('Error creating checkout session:', error);
      }
    };

    initiateCheckout();

    return () => {
      isMounted = false;
      if (checkout) {
        checkout.destroy();
        setCheckout(null);
      }
    };
  }, [stripe, planToPurchase, quantityToPurchase, isModalOpen]);

  const handlePurchase = async (planType) => {
    if (!isAuthenticated) {
      // Redirect to signup if not authenticated
      const params = new URLSearchParams({
        redirectAfterAuth: '/pricing',
        selectedPlan: planType,
        quantity: counters[planType]
      });
      navigate(`/login?${params.toString()}`);
      return;
    }

    // If already authenticated, set the states directly
    setPlanToPurchase(planType);
    setQuantityToPurchase(counters[planType]);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    if (checkout) {
      checkout.destroy();
      setCheckout(null);
    }
    setIsModalOpen(false);
    setPlanToPurchase(null);
    setQuantityToPurchase(null);
    navigate('/pricing', { replace: true });
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (checkout) {
        checkout.destroy();
        setCheckout(null);
      }
    };
  }, []);

  const plans = {
    free: {
      title: "Basis",
      price: "Free",
      features: [
        "Find new products every 1 hour",
        "Notification of New Products"
      ]
    },
    premium: {
      title: "Premium",
      price: 4780,
      features: [
        "Find new products every 10 minutes",
        "Notification of New Products"
      ]
    }
  };

  return (
    <section className="home-pricing-section">
      <div className="home-pricing-container">
        <div className="home-pricing-heading">
          <h1>Pricing</h1>
          <p>Be the first to know about the best products. All in one efficient interface.</p>
        </div>

        <div className="home-pricing-table-wrapper">
          <PricingPlan
            type="free"
            {...plans.free}
            counter={counters.free}
            updateCounter={updateCounter}
          />
          <PricingPlan
            type="premium"
            {...plans.premium}
            counter={counters.premium}
            updateCounter={updateCounter}
            onPurchase={() => handlePurchase('premium')}
          />
        </div>

        <PricingSummary
          counters={counters}
          pricing={pricing}
        />
      </div>

      <div
        id="checkout"
        className={`embedded-checkout-container ${isModalOpen ? 'active' : ''}`}
      ></div>
      {isModalOpen && (
        <div
          className="embedded-checkout-overlay active"
          onClick={closeModal}
        ></div>
      )}
    </section>
  );
};

export default Pricing;
