import { API_URL } from '../config';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './modelsEdit.css';
const ModelsEdit = () => {
  const { marketId } = useParams();
  const navigate = useNavigate();
  const [market, setMarket] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const fetchMarket = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/markets/${marketId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setMarket(response.data);
      } catch (error) {
        console.error('Error fetching market:', error);
        setErrorMessage('Error fetching market data.');
      }
    };

    fetchMarket();
  }, [marketId]);

  const handleDeleteMarket = async () => {
    if (!window.confirm('Are you sure you want to delete this market?')) {
      return;
    }

    setIsDeleting(true);
    try {
      const token = localStorage.getItem('token');

      // Delete the market
      const response = await axios.delete(`${API_URL}/api/markets/${marketId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Wait for subscription summary to be updated
      const summaryResponse = await axios.get(`${API_URL}/api/subscriptions/summary`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Navigate back to models page with both market deletion and subscription data
      navigate('/models', {
        state: {
          marketDeleted: true,
          marketId: response.data.marketId,
          message: 'Market successfully deleted',
          updatedSubscriptionSummary: summaryResponse.data
        }
      });
    } catch (error) {
      console.error('Error deleting market:', error);
      setErrorMessage(error.response?.data?.error || 'Error deleting market.');
    } finally {
      setIsDeleting(false);
    }
  };

  if (!market) {
    return <div>Loading...</div>;
  }

  return (
    <div className="models-edit-container">
      <h1>{market.name}</h1>
      <div className="market-details">
        <p><strong>Keyword:</strong> {market.keyword}</p>
        <p><strong>Location:</strong> {market.location}</p>
        <p><strong>Markets:</strong> {market.markets.join(', ')}</p>
        <p><strong>Price Range:</strong> {market.minPrice || 'No min'} - {market.maxPrice || 'No max'}</p>
      </div>

      <div className="action-buttons">
        <button
          className="delete-button"
          onClick={handleDeleteMarket}
          disabled={isDeleting}
        >
          {isDeleting ? 'Deleting...' : 'Delete Market'}
        </button>
        <button
          className="back-button"
          onClick={() => navigate('/models')}
        >
          Back to Markets
        </button>
      </div>

      {errorMessage && (
        <div className="error-message">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default ModelsEdit;
