import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentPages.css';

const PaymentCancel = () => {
  const navigate = useNavigate();

  const handleTryAgain = () => {
    navigate('/models');
  };

  return (
    <div className="payment-result-container">
      <div className="payment-result-card">
        <div className="cancel-icon">×</div>
        <h2>Payment Cancelled</h2>
        <p>Your payment was cancelled and no charges were made.</p>
        <button onClick={handleTryAgain} className="continue-button">
          Return to Dashboard
        </button>
      </div>
    </div>
  );
};

export default PaymentCancel;
