// src/pages/LandingPage/components/Features.jsx
import React from 'react';
import PropTypes from 'prop-types';

const featuresData = [
  {
    id: 'dashboard',
    title: 'Vezérlőpult',
    content: 'A vezérlőpult egy központi hely, ahol minden fontos adatot átláthatóan kezelhet. Nyomon követheti a legfontosabb mutatókat és gyorsan hozzáférhet a legfontosabb funkciókhoz.'
  },
  {
    id: 'notifications',
    title: 'Értesítések a legújabb termékekről',
    content: 'Azonnali értesítéseket kap a legújabb termékekről, hogy mindig elsőként értesüljön az új ajánlatokról és lehetőségekről. Soha ne maradjon le egy jó üzletről!'
  },
  {
    id: 'multiplatform',
    title: 'Több Platform Termékei Egy Helyen',
    content: 'Minden terméket egy helyen találhat, függetlenül attól, hogy melyik platformról származik. Ez időt takarít meg és egyszerűsíti a keresést.'
  },
  {
    id: 'profit',
    title: 'Egyedi Profit kalkuláció',
    content: 'Személyre szabott profit számítások, amelyek segítenek pontosan meghatározni az Ön nyereségét. Azonnali és pontos pénzügyi elemzések, amelyek segítenek az egyszerűbb döntéshozatalban.'
  },
  {
    id: 'cross-platform',
    title: 'Cross-Platform Szűrés',
    content: 'Keresés több platformon egyszerre. Ezzel a funkcióval gyorsan és egyszerűen megtalálhatja a legjobb ajánlatokat és termékeket, bármely platformon is legyenek elérhetők.'
  },
  {
    id: 'analytics',
    title: 'Analika',
    content: 'Elemzések és statisztikák, amelyek segítenek mélyebb betekintést nyerni az üzleti teljesítménybe. Ezek az adatok segítenek az üzleti stratégia finomításában és a piaci trendek megértésében.'
  },
  {
    id: 'ai',
    title: 'Mesterséges Intelligencia',
    content: 'AI alapú megoldások, amelyek segítenek az adatfeldolgozásban, elemzésben és előrejelzésekben. A mesterséges intelligencia segít azonosítani a mintákat és ajánlásokat tesz az üzleti folyamatok javítására.'
  }
];

const Features = ({ activeDropdowns, toggleDropdown }) => {
  return (
    <section className="home-features-section">
      <div className="home-features-section-heading">
        <h1>
          Miért a
          <img
            src="/assets/img/profx.png"
            alt="ProfitX Logo"
            className="home-logo-image"
          />
        </h1>
      </div>

      <p>
        Belefáradt, hogy lemarad a legjobb ajánlatokról a piactereken?
        Kapjon értesítést. Növelje nyereségét.<br />
        Irányítson mindent egy felületről, és ne pazarolja tovább az idejét.
      </p>

      <div className="home-features-container">
        <div className="home-features-text">
          <div className="rect-leftBg">
            <img
              src="/assets/img/left_bg.webp"
              alt="ProfitXBG"
            />
          </div>

          <h3>Funkciók</h3>

          {featuresData.map((feature) => (
            <div
              key={feature.id}
              className={`home-dropdown ${activeDropdowns.has(feature.id) ? 'active' : ''}`}
            >
              <button
                className="home-dropdown-btn"
                onClick={() => toggleDropdown(feature.id)}
              >
                {feature.title}
              </button>
              <div className="home-dropdown-content">
                <p>{feature.content}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="home-features-image">
          <img
            src="/assets/img/dashboard.png"
            alt="Dashboard"
          />
          <button className="home-cta-button">
            Vágjunk bele Ingyen!
          </button>
        </div>
      </div>

      <div className="home-platforms-container">
        <img
          src="/assets/img/markets.png"
          alt="Markets"
        />
      </div>
    </section>
  );
};

Features.propTypes = {
  activeDropdowns: PropTypes.instanceOf(Set).isRequired,
  toggleDropdown: PropTypes.func.isRequired
};

export default Features;
