import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { API_URL } from '../config';
import './paymentReturn.css';

const PaymentReturn = () => {
  const { sessionId } = useParams();
  const [status, setStatus] = useState('loading');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [cardClass, setCardClass] = useState('');
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_URL}/api/payment/verify/${sessionId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 401) {
          setStatus('unauthorized');
          setCardClass('failed');
          return;
        }

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            setStatus('success');
            setCardClass('success');
            startCountdown();
          } else {
            setStatus('failed');
            setCardClass('failed');
            setError('Payment verification failed. Please contact support if you believe this is an error.');
          }
        } else {
          setStatus('failed');
          setCardClass('failed');
          const errorData = await response.json();
          setError(errorData.error || 'An unexpected error occurred. Please try again later.');
        }
      } catch (err) {
        setStatus('failed');
        setCardClass('failed');
        setError('Network error. Please check your connection and try again.');
      }
    };

    verifyPayment();
  }, [sessionId, navigate]);

  const startCountdown = () => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          navigate('/dashboard');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleDashboard = () => {
    navigate('/dashboard');
  };

  const handleTryAgain = () => {
    navigate('/pricing');
  };

  return (
    <div className="payment-return-container">
      <div className={`payment-status-card ${cardClass}`}>
        {status === 'loading' && (
          <>
            <div className="loading-spinner" />
            <h2>Verifying Your Payment</h2>
            <p>Please wait while we confirm your transaction...</p>
          </>
        )}

        {status === 'success' && (
          <>
            <div className="success-icon">✓</div>
            <h2>Payment Successful!</h2>
            <p>Thank you for your purchase. Your account has been upgraded successfully.</p>
            <button onClick={handleDashboard}>Go to Dashboard</button>
            <p className="redirect-message">
              Redirecting to dashboard in <span className="countdown">{countdown}</span> seconds...
            </p>
          </>
        )}

        {status === 'failed' && (
          <>
            <div className="error-icon">×</div>
            <h2>Payment Failed</h2>
            {error && <p className="error-message">{error}</p>}
            <button onClick={handleTryAgain}>Try Again</button>
          </>
        )}

        {status === 'unauthorized' && (
          <>
            <div className="error-icon">!</div>
            <h2>Authentication Required</h2>
            <p>Please log in to verify your payment and access your account.</p>
            <button className="login-button" onClick={handleLogin}>
              Log In Now
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentReturn;
