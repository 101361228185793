// src/pages/LandingPage/components/Hero.jsx
import React from 'react';
import PropTypes from 'prop-types';

const Hero = ({ handleNavigate }) => {
  return (
    <section className="home-heroSection">
      <div className="home-heroContainer">
        <div className="rectBg">
          <img
            src="/assets/img/right_bg.png"
            alt="ProfitXBG"
          />
        </div>

        <div className="home-colLeft">
          <h4>AI Kereskedelem</h4>
          <div className="home-separator"></div>
          <h1>
            Többet ne maradjon le a <span>legjobb</span> üzletekről
          </h1>
          <p>
            Azonnali értesítések a különböző piactereken található termékekről.
          </p>
          <button
            className="home-cta-button"
            onClick={() => handleNavigate('/login')}
          >
            Kezdjünk bele
          </button>
          <div className="app-download">
            <img
              src="/assets/img/appstore.png"
              alt="AppStore"
              className="app-store"
              draggable="false"
            />
            <img
              src="/assets/img/googleplay.png"
              alt="GooglePlay"
              className="google-play"
              draggable="false"
            />
          </div>
        </div>

        <div className="home-colRight">
          <img
            src="/assets/img/profitx-hero.png"
            alt="ProfitX Alert"
          />
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = {
  handleNavigate: PropTypes.func.isRequired
};

export default Hero;
