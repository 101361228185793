import { API_URL } from './config';
import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import Sidebar from './components/Sidebar';
import TopBar from './components/Topbar';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Products from './pages/Products';
import Analytics from './pages/Analytics';
import Models from './pages/Models';
import SheetEdit from './pages/SheetEdit';
import ModelsEdit from './pages/ModelsEdit';
import Tracking from './pages/Tracking';
import Reports from './pages/Reports';
import Settings from './pages/Settings';
import Login from './pages/Login';
import LandingPage from './pages/LandingPage/LandingPage';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentCancel from './pages/PaymentCancel';
import HideNotNeeded from './HideNotNeeded/HideNotNeeded';
import ProtectedRoute from './components/ProtectedRoute';
import NotFound from './pages/NotFound';
import useAuth from './hooks/useAuth';
import PaymentReturn from './pages/PaymentReturn';

const App = () => {
  const { token, loading } = useAuth();
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    status: 'None',
  });

  const fetchUserData = useCallback(async () => {
    if (!token) return;
    try {
      const response = await fetch(`${API_URL}/api/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [token]);

  useEffect(() => {
    fetchUserData();
  }, [token, fetchUserData]);

  const location = useLocation();
  const isPublicPage = location.pathname === '/' ||
                      location.pathname === '/login' ||
                      location.pathname === '/pricing';
  const isPaymentPage = location.pathname.startsWith('/payment/');

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="app-container">
      {!isPublicPage && !isPaymentPage && location.pathname !== '/404' && <Sidebar />}
      <div className={`main-content ${isPublicPage || isPaymentPage || location.pathname === '/404' ? 'full-width' : ''}`}>
        <HideNotNeeded>
          {!isPublicPage && !isPaymentPage && location.pathname !== '/404' && <TopBar userData={userData} />}
        </HideNotNeeded>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/pricing" element={<LandingPage />} />
          <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} />} />
          <Route path="/products" element={<ProtectedRoute element={Products} />} />
          <Route path="/analytics" element={<ProtectedRoute element={Analytics} />} />
          <Route path="/models" element={<ProtectedRoute element={Models} />} />
          <Route path="/models/sheet-edit/:sheetId" element={<ProtectedRoute element={SheetEdit} />} />
          <Route path="/models/market-edit/:marketId" element={<ProtectedRoute element={ModelsEdit} />} />
          <Route path="/tracking" element={<ProtectedRoute element={Tracking} />} />
          <Route path="/reports" element={<ProtectedRoute element={Reports} />} />
          <Route path="/settings" element={<ProtectedRoute element={() => <Settings userData={userData} fetchUserData={fetchUserData} />} />} />
          <Route path="/payment/success" element={<ProtectedRoute element={PaymentSuccess} />} />
          <Route path="/payment/cancel" element={<ProtectedRoute element={PaymentCancel} />} />
          <Route path="/payment/return/:sessionId" element={<PaymentReturn />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </div>
    </div>
  );
};

const AppWrapper = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWrapper;
