// src/pages/LandingPage/components/Header.jsx
import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ isScrolled, handleNavigate }) => {
  return (
    <nav className="home-navbar">
      <div className={`home-navbar-container ${isScrolled ? 'scrolled' : ''}`}>
        <div className="home-logo">
          <img
            src="/assets/img/profx.png"
            alt="ProfitX Logo"
            className="home-logo-image"
          />
        </div>

        <ul className="home-nav-links">
          <li><a href="#funkciok">Funkciók</a></li>
          <li><a href="#miert">Miért a ProfitX</a></li>
          <li><a href="#business">Business</a></li>
          <li><a href="#kapcsolat">Kapcsolat</a></li>
        </ul>

        <div className="home-nav-actions">
          <div className="home-profile-icon">
            <img
              src="/assets/img/user.png"
              alt="Profile Icon"
              onClick={() => handleNavigate('/login')}
            />
          </div>
          <button
            className="home-cta-button"
            onClick={() => handleNavigate('/login')}
          >
            Kezdés →
          </button>
        </div>
      </div>
    </nav>
  );
};

Header.propTypes = {
  isScrolled: PropTypes.bool.isRequired,
  handleNavigate: PropTypes.func.isRequired
};

export default Header;
