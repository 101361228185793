// src/pages/LandingPage/components/FAQ.jsx
import React from 'react';
import PropTypes from 'prop-types';

const faqData = [
  {
    id: 'market',
    question: 'Hogyan lehet saját piacot létrehozni?',
    answer: 'Kérjük vegye fel velünk a kapcsolatot, hogy az Ön igényeinek megfelelő egyedi piacot alakítsunk ki, amelyet tevékenységének vagy üzletének megfelelő speciális szűrésekkel és kérésekkel szabhat személyre.'
  },
  {
    id: 'pricelist',
    question: 'Hogyan tudok Árlistát hozzáadni?',
    answer: 'Egyszerűen és gyorsan létrehozhat saját árlistát. Kiválasztja a termékeket és hozzá a sajátosságaikat, megadja az eladási árakat, és a rendszer automatikusan értesíti Önt az új, nyereséget hozó termékekről. Így mindig időben értesülhet a legjobb ajánlatokról.'
  },
  {
    id: 'notifications',
    question: 'Hogyan tudom az Értesítéseket bekapcsolni?',
    answer: 'Győződjön meg róla, hogy az értesítések engedélyezve vannak a fiókbeállításokban. Az értesítési beállítások részben testreszabhatja, milyen típusú értesítéseket szeretne kapni.'
  },
  {
    id: 'browser',
    question: 'A ProfitX elérhető webböngészőn keresztül?',
    answer: 'Igen! A webapplikációnk segítségével mélyebb betekintést nyerhet a piacra, és több adatot használhat fel a termékek keresése során. Asztali számítógépén vagy laptopján keresztül e-mail értesítéseket is kaphat.'
  },
  {
    id: 'resell',
    question: 'Elakarom kezdeni a Viszonteladást \'Flippelést\' de nem tudom hol kezdjem...',
    answer: 'Mint flipperek, a legtöbb pénzt úgy keressük, hogy alulértékelt dolgokat vásárolunk és megfelelő áron eladjuk őket. Válasszon egy terméket, vizsgálja meg az eladási árakat és állítson értesítést alacsonyabb árra. A ProfitX értesítést küld ha alulértékelt termék jelentkezik a piacon, így elsőként csphat le rá.'
  }
];

const EmailBox = () => (
  <div className="faq-box faq-box-email">
    <h2>Email</h2>
    <p>Általános érdeklődés esetén kérjük, írjon nekünk e-mailt, és a csapat a lehető leggyorsabban válaszol.</p>
    <button className="faq-box-email-cta">
      <img src="/assets/img/email.png" alt="Email Icon" />
      info@profitx.hu
    </button>
  </div>
);

const FAQBox = () => (
  <div className="faq-box faq-box-gyik">
    <h2>Gyik</h2>
    <p>Általános érdeklődés esetén kérjük, írjon nekünk e-mailt, és a csapat a lehető leggyorsabban válaszol.</p>
    <button className="faq-box-gyik-cta">Tovább</button>
  </div>
);

const TopQuestions = ({ activeDropdowns, toggleDropdown }) => (
  <div className="faq-bottom-right">
    {faqData.map((item) => (
      <div
        key={item.id}
        className={`faq-dropdown ${activeDropdowns.has(item.id) ? 'active' : ''}`}
      >
        <button
          className="faq-dropdown-btn"
          onClick={() => toggleDropdown(item.id)}
        >
          {item.question}
        </button>
        <div className="faq-dropdown-content">
          <p>{item.answer}</p>
        </div>
      </div>
    ))}
  </div>
);

const FAQ = ({ activeDropdowns, toggleDropdown }) => {
  return (
    <section className="faq-section">
      <div className="faq-container">
        <div className="faq-top">
          <div className="faq-heading">
            <h1>Segítség</h1>
          </div>
          <div className="faq-boxes">
            <EmailBox />
            <FAQBox />
          </div>
        </div>
      </div>

      <div className="faq-bottom">
        <div className="faq-bottom-left">
          <h2><span>TOP </span>5 KÉRDÉS</h2>
          <p>
            Megválaszoltatlan kérdés vagy egyéb indok miatt, kérjük vegye fel velünk a kapcsolatot Emailben.
          </p>
          <button className="top5-cta">Kapcsolat</button>
        </div>

        <TopQuestions
          activeDropdowns={activeDropdowns}
          toggleDropdown={toggleDropdown}
        />
      </div>
    </section>
  );
};

FAQ.propTypes = {
  activeDropdowns: PropTypes.instanceOf(Set).isRequired,
  toggleDropdown: PropTypes.func.isRequired
};

TopQuestions.propTypes = {
  activeDropdowns: PropTypes.instanceOf(Set).isRequired,
  toggleDropdown: PropTypes.func.isRequired
};

export default FAQ;
